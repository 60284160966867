import React from 'react';

import './contact-button.css';

const ContactButton = ({ text }) => (
    <div className="contact-button">
        <a href="mailto:emeraldlessley@gmail.com">{text}</a>
    </div>
);

export default ContactButton;
