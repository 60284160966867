import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Columns from '../components/columns';
import headshot from '../images/headshot.png';
import ContactButton from '../components/contact-button'
import EmeraldLessley from '../components/emerald';

const Soprano = () => (
  <Layout>
    <SEO title="Soprano" />
    <Columns>
      <img src={headshot} alt="Emerald Lessley" className="mobile-hide" />
      <div className="maintain-padding" style={{ paddingTop: '3rem' }}>
        <EmeraldLessley small centered={false} />
        <h3>American Soprano</h3>
        <p>A native of of the Bay Area, Emerald Lessley has lived and performed across the U.S. and in Europe. Her favorite operatic roles include Fiordiligi in Mozart’s Così fan tutte, Eurydice in Glück’s Orphée et Eurydice, Donna Anna in Mozart's Don Giovanni, Suor Angelica in Puccini’s Suor Angelica, Calisto in Cavalli’s La Calisto, Semele in Handel’s Semele, Dido in Purcell’s Dido and Aeneas, Second Lady in Mozart’s Die Zauberflöte, Gasparina in Haydn’s La Canterina, Geraldine in Barber’s A Hand of Bridge, and La Ciesca in Puccini’s Gianni Schicchi. </p>
        <p>In addition to traditional operatic repertoire, Emerald is an active performer of new music. She loves premiering new works and was thrilled to perform the role of Belisa in the U.S. premiere of Maderna’s opera Don Perlimplìn. She has also enjoyed recent collaborations with composers on concerts in both the U.S. and Europe.</p>
        <ContactButton text="Contact Me for Availability" />
      </div>
    </Columns>
  </Layout>
)

export default Soprano;
